import React from "react";
import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";
import {
  Box,
  Container,
  Typography,
  Grid,
  IconButton,
  Stack,
  List,
  ListItem,
  useTheme,
} from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";

// Styled Components
const StyledFooter = styled("footer")(({ theme }) => ({
  background: "linear-gradient(135deg, #1a237e 0%, #1976d2 100%)",
  color: "white",
  position: "relative",
  overflow: "hidden",
  "&::before": {
    content: '""',
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background:
      "radial-gradient(circle at 50% 0%, rgba(255,255,255,0.1) 0%, rgba(255,255,255,0) 70%)",
  },
}));

const FooterLink = styled(Link)(({ theme }) => ({
  color: "rgba(255,255,255,0.8)",
  textDecoration: "none",
  transition: "all 0.3s ease",
  display: "inline-block",
  position: "relative",
  "&::after": {
    content: '""',
    position: "absolute",
    width: "0%",
    height: "2px",
    bottom: -2,
    left: 0,
    background: "white",
    transition: "width 0.3s ease",
  },
  "&:hover": {
    color: "white",
    transform: "translateX(8px)",
    "&::after": {
      width: "100%",
    },
  },
}));

const SocialButton = styled(IconButton)(({ theme }) => ({
  color: "white",
  background: "rgba(255,255,255,0.1)",
  backdropFilter: "blur(10px)",
  border: "1px solid rgba(255,255,255,0.2)",
  transition: "all 0.3s ease",
  "&:hover": {
    background: "rgba(255,255,255,0.2)",
    transform: "translateY(-4px)",
    boxShadow: "0 8px 16px rgba(0,0,0,0.1)",
  },
}));

const Footer = () => {
  const theme = useTheme();

  return (
    <StyledFooter>
      <Container maxWidth="lg" sx={{ py: 8 }}>
        <Grid container spacing={4}>
          {/* Company Info */}
          <Grid item xs={12} md={4}>
            <Typography
              variant="h5"
              sx={{
                fontWeight: 700,
                mb: 3,
                background: "linear-gradient(135deg, #fff 0%, #e3f2fd 100%)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
            >
              National IT Services
            </Typography>
            <Stack spacing={2} sx={{ color: "rgba(255,255,255,0.8)" }}>
              <Typography>Email: info@itforsme.in</Typography>
              <Typography>Phone: +91 98119 98370</Typography>
              <Typography>GST No: 06AFWPJ6706R1ZI</Typography>
            </Stack>
          </Grid>

          {/* Quick Links */}
          <Grid item xs={12} md={4}>
            <Typography
              variant="h6"
              sx={{
                fontWeight: 600,
                mb: 3,
                color: "white",
              }}
            >
              Quick Links
            </Typography>
            <List sx={{ p: 0 }}>
              {[
                { text: "All Services", path: "/all-services" },
                { text: "Offers", path: "/offers" },
                { text: "Blogs", path: "/blogs" },
                { text: "Get In Touch", path: "/get-in-touch" },
              ].map((link) => (
                <ListItem key={link.text} sx={{ p: 1 }}>
                  <FooterLink to={link.path}>{link.text}</FooterLink>
                </ListItem>
              ))}
            </List>
          </Grid>

          {/* Social Links */}
          <Grid item xs={12} md={4}>
            <Typography
              variant="h6"
              sx={{
                fontWeight: 600,
                mb: 3,
                color: "white",
              }}
            >
              Connect with Us
            </Typography>
            <Typography
              sx={{
                mb: 3,
                color: "rgba(255,255,255,0.8)",
              }}
            >
              Follow us on social media for updates and news.
            </Typography>
            <Stack direction="row" spacing={2}>
              <SocialButton
                href="https://www.facebook.com/nationalitservices022"
                target="_blank"
                rel="noreferrer"
                aria-label="Facebook"
              >
                <FacebookIcon />
              </SocialButton>
              <SocialButton
                href="https://twitter.com/NationalItServ2"
                target="_blank"
                rel="noreferrer"
                aria-label="Twitter"
              >
                <TwitterIcon />
              </SocialButton>
              <SocialButton
                href="https://www.linkedin.com/company/65726297/admin/feed/posts/"
                target="_blank"
                rel="noreferrer"
                aria-label="LinkedIn"
              >
                <LinkedInIcon />
              </SocialButton>
              <SocialButton
                href="https://www.instagram.com/national_it_services/"
                target="_blank"
                rel="noreferrer"
                aria-label="Instagram"
              >
                <InstagramIcon />
              </SocialButton>
            </Stack>
          </Grid>
        </Grid>
      </Container>

      {/* Copyright */}
      <Box
        sx={{
          py: 3,
          textAlign: "center",
          borderTop: "1px solid rgba(255,255,255,0.1)",
          background: "rgba(0,0,0,0.1)",
          position: "relative",
        }}
      >
        <Typography
          sx={{
            color: "rgba(255,255,255,0.8)",
            fontSize: "0.875rem",
          }}
        >
          &copy; {new Date().getFullYear()} National IT Services. All rights
          reserved.
        </Typography>
      </Box>
    </StyledFooter>
  );
};

export default Footer;
