const popularBrandsData = [
  {
    id: 1,
    title: "Zoom Meeting and Webinar",
    description:
      "Professional video conferencing with 30-hour meeting duration, Mail and Calendar Client & Service, Clips Plus Unlimited videos, Docs Unlimited, 5GB Cloud Storage, AI Companion, and Essential Apps included. Free premium apps for 1 year.",
    price: "",
    originalPrice: "",
    discount: "Flat 5% Off",
    imageSrc:
      "https://img.freepik.com/premium-vector/zoom-icon-popular-messenger-app-communications-platform_277909-457.jpg?semt=ais_hybrid",
    category: "Collaboration Solutions",
  },
  {
    id: 2,
    title: "TeamViewer Remote Access",
    description:
      "Secure remote access and support solution for seamless connectivity across devices and platforms. Ideal for IT support and remote collaboration.",
    price: "Let us Talk",
    originalPrice: "",
    discount: "Flat 10% Off",
    imageSrc:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQPCKK3xykPYAVnFgFxhbQvmwjb8SuZ3jZhfQ&s",
    category: "Collaboration Solutions",
  },
  {
    id: 3,
    title: "GOTO Resolve",
    description:
      "All-in-one IT management & support solution that combines remote access, automation, and ticket management to streamline IT operations.",
    price: "Let us Talk",
    originalPrice: "",
    discount: "Flat 10% Off",
    imageSrc:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRRmXHpIkhZOxNo8nsvqIZdbEox-098-2oscw&s",
    category: "Collaboration Solutions",
  },
  {
    id: 4,
    title: "Freshworks CRM",
    description:
      "Freshworks Customer Relationship Management software provides an organization with a single view of its customers, helps automate critical sales processes ",
    price: "Let us Talk",
    originalPrice: "",
    discount: "Flat 10% Off",
    imageSrc:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQcJY9Nmlo8j0vUKXMIARq4sQ21Gd1DXz1E1w&s",
    category: "Business Applications",
  },
  {
    id: 5,
    title: "Zoho Suite",
    description:
      "Comprehensive suite of online business, productivity and collaboration applications. Includes CRM, Mail, Workplace, and more.",
    price: "Let us Talk",
    originalPrice: "",
    discount: "Flat 10% Off",
    imageSrc:
      "https://download.logo.wine/logo/Zoho_Corporation/Zoho_Corporation-Logo.wine.png",
    category: "Business Applications",
  },
  {
    id: 6,
    title: "greytHR",
    description:
      "Cloud-based HR & Payroll software that simplifies HR operations, payroll processing, leave management, and attendance tracking.",
    price: "Let us Talk",
    originalPrice: "",
    discount: "Flat 10% Off",
    imageSrc:
      "https://bsmedia.business-standard.com/_media/bs/img/article/2024-02/19/full/20240219171029.jpg",
    category: "Business Applications",
  },
];

export default popularBrandsData;
